import GridCell from "$components/grid/react-grid/cell/grid-cell";
import { IReactGridColumn } from "$components/grid/react-grid/grid.react";
import RefFilter from "$pages/sitelistpage-react/modules/filters/reffilter/reffilter";
import StringFilter from "$pages/sitelistpage-react/modules/filters/stringfilter/stringfilter.react";
import { DeliveryNotesList, ElasticSearchPage } from "$typings/graphql-codegen";
import React from "react";
import { ColumnSortProperty } from "../../../src/config";
import DateFilter from "$pages/sitelistpage-react/modules/filters/datefilter/datefilter.react";
import { pureFormatDate } from "$lib/dateHelpers";
import NumberFilter from "$pages/sitelistpage-react/modules/filters/numberfilter/numberfilter.react";
import LastDeliveryTicket from "$pages/sitelistpage-react/modules/columns/lastdeliveryticket/lastdeliveryticket.react";
import BooleanFilter from "$pages/sitelistpage-react/modules/filters/booleanfilter/booleanfilter.react";
import classNames from "classnames";

const getProductTranslationByLanguage = (
  language: string
): keyof DeliveryNotesList["edges"][0] => {
  switch (language) {
    case "de-DE":
      return "productAlias_deDE";
    case "en-GB":
      return "productAlias_enGB";
    case "es-ES":
      return "productAlias_esES";
    case "fr-FR":
      return "productAlias_frFR";
    case "hu-HU":
      return "productAlias_huHU";
    case "it-IT":
      return "productAlias_itIT";
    case "nb-NO":
      return "productAlias_nbNO";
    case "nl-NL":
      return "productAlias_nlNL";
    case "pl-PL":
      return "productAlias_plPL";
    case "sv-SE":
      return "productAlias_svSE";
  }
  return "productName";
};

export const getColumns = (
  language: string
): IReactGridColumn<DeliveryNotesList["edges"][0]>[] => [
  {
    columnTitle: "UI_Dnm_Column_SiteName",
    columnKey: ColumnSortProperty.SiteName,
    render: (item, width, highlightText) => (
      <GridCell
        width={width}
        highlightText={highlightText}
        showPointerIfLink
        className={classNames(item.siteId && "link")}
        columnLink={item.siteId ? `/sitedetails/${item.siteId}` : ""}
      >
        {item.siteName}
      </GridCell>
    ),
    initialWidth: 200,
    managementGroup: "UI_DNM_Group_Site",
    filter: {
      name: "UI_Dnm_Column_SiteName",
      property: "sitename",
      component: StringFilter
    }
  },
  {
    columnTitle: "UI_Dnm_Column_BUID",
    columnKey: ColumnSortProperty.BUID,
    render: (item, width, highlightText) => (
      <GridCell width={width} highlightText={highlightText}>
        {item.buid}
      </GridCell>
    ),
    initialWidth: 200,
    managementGroup: "UI_DNM_Group_Site",
    filter: {
      name: "UI_Dnm_Column_BUID",
      property: "buid",
      component: RefFilter,
      componentProps: {
        page: ElasticSearchPage.DeliveryNotes
      }
    }
  },
  {
    columnTitle: "UI_Dnm_Column_AccessTag",
    columnKey: ColumnSortProperty.CustomerName,
    render: (item, width, highlightText) => (
      <GridCell width={width} highlightText={highlightText}>
        {item.customerName}
      </GridCell>
    ),
    initialWidth: 200,
    managementGroup: "UI_DNM_Group_Site",
    filter: {
      name: "UI_Dnm_Column_AccessTag",
      property: "customer",
      component: StringFilter
    }
  },
  {
    columnTitle: "UI_Dnm_Column_ProductName",
    columnKey: ColumnSortProperty.ProductName,
    render: (item, width, highlightText) => (
      <GridCell width={width} highlightText={highlightText}>
        {item[getProductTranslationByLanguage(language)]}
      </GridCell>
    ),
    initialWidth: 200,
    managementGroup: "UI_DNM_Group_Site",
    filter: {
      name: "UI_Dnm_Column_ProductName",
      property: "product",
      component: RefFilter,
      componentProps: {
        page: ElasticSearchPage.DeliveryNotes
      }      
    }
  },
  {
    columnTitle: "UI_Dnm_Column_CountryCode",
    columnKey: ColumnSortProperty.CountryCode,
    render: (item, width, highlightText) => (
      <GridCell width={width} highlightText={highlightText}>
        {item.countryCode}
      </GridCell>
    ),
    initialWidth: 200,
    managementGroup: "UI_DNM_Group_Site",
    filter: {
      name: "UI_Dnm_Column_CountryCode",
      property: "countryCode",
      component: StringFilter
    }
  },
  {
    columnTitle: "UI_Dnm_Column_SoldTo",
    columnKey: ColumnSortProperty.SoldTo,
    render: (item, width, highlightText) => (
      <GridCell width={width} highlightText={highlightText}>
        {item.soldTo}
      </GridCell>
    ),
    initialWidth: 200,
    managementGroup: "UI_DNM_Group_Site",
    filter: {
      name: "UI_Dnm_Column_SoldTo",
      property: "soldTo",
      component: StringFilter
    }
  },
  {
    columnTitle: "UI_Dnm_Column_RefillDate",
    columnKey: ColumnSortProperty.RefillDate,
    render: (item, width, highlightText) => (
      <GridCell width={width} highlightText={highlightText}>
        {item.refillDate && pureFormatDate(item.refillDate, true, ".")}
      </GridCell>
    ),
    initialWidth: 200,
    managementGroup: "UI_DNM_Group_TankChannelRefill",
    filter: {
      name: "UI_Dnm_Column_RefillDate",
      property: "refillDate",
      component: DateFilter
    }
  },
  {
    columnTitle: "UI_Dnm_Column_AmountFilled",
    columnKey: ColumnSortProperty.AmountFilled,
    render: (item, width, highlightText) => (
      <GridCell width={width} highlightText={highlightText}>
        {item.amountFilled}
      </GridCell>
    ),
    initialWidth: 200,
    managementGroup: "UI_DNM_Group_TankChannelRefill",
    filter: {
      name: "UI_Dnm_Column_AmountFilled",
      property: "amountFilled",
      component: NumberFilter
    }
  },
  {
    columnTitle: "UI_Dnm_Column_Source",
    columnKey: ColumnSortProperty.Source,
    render: (item, width, highlightText) => (
      <GridCell width={width} highlightText={highlightText}>
        {item.source}
      </GridCell>
    ),
    initialWidth: 200,
    managementGroup: "UI_DNM_Group_TankChannelRefill",
    filter: {
      name: "UI_Dnm_Column_Source",
      property: "source",
      component: StringFilter
    }
  },
  {
    columnTitle: "UI_Dnm_Column_ShipTo",
    columnKey: ColumnSortProperty.ShipTo,
    render: (item, width, highlightText) => (
      <GridCell
        width={width}
        className={classNames((item.siteId && item.siteChannelId) && 'link')}
        showPointerIfLink
        columnLink={
          item.siteId && item.siteChannelId
            ? `/sitedetails/${item.siteId}/tanks/tankDetails/${item.siteChannelId}`
            : ""
        }
        highlightText={highlightText}
      >
        {item.shipTo}
      </GridCell>
    ),
    initialWidth: 200,
    managementGroup: "UI_DNM_Group_TankChannelRefill",
    filter: {
      name: "UI_Dnm_Column_ShipTo",
      property: "shipTo",
      component: StringFilter
    }
  },
  {
    columnTitle: "UI_Dnm_Column_SAPAddress1",
    columnKey: ColumnSortProperty.SapAddress,
    render: (item, width, highlightText) => (
      <GridCell width={width} highlightText={highlightText}>
        {item.sapAddress}
      </GridCell>
    ),
    initialWidth: 200,
    managementGroup: "UI_DNM_Group_TankChannelRefill",
    filter: {
      name: "UI_Dnm_Column_SAPAddress1",
      property: "sapAddress",
      component: StringFilter
    }
  },
  {
    columnTitle: "UI_Dnm_Column_SAPCity",
    columnKey: ColumnSortProperty.SapCity,
    render: (item, width, highlightText) => (
      <GridCell width={width} highlightText={highlightText}>
        {item.sapCity}
      </GridCell>
    ),
    initialWidth: 200,
    managementGroup: "UI_DNM_Group_TankChannelRefill",
    filter: {
      name: "UI_Dnm_Column_SAPCity",
      property: "sapCity",
      component: StringFilter
    }
  },
  {
    columnTitle: "UI_Dnm_Column_SAPPostCode",
    columnKey: ColumnSortProperty.SapPostCode,
    render: (item, width, highlightText) => (
      <GridCell width={width} highlightText={highlightText}>
        {item.sapPostCode}
      </GridCell>
    ),
    initialWidth: 200,
    managementGroup: "UI_DNM_Group_TankChannelRefill",
    filter: {
      name: "UI_Dnm_Column_SAPPostCode",
      property: "sapPostCode",
      component: StringFilter
    }
  },
  {
    columnTitle: "UI_Dnm_Column_RefillReference",
    columnKey: ColumnSortProperty.Reference,
    render: (item, width, highlightText) => (
      <GridCell width={width} highlightText={highlightText}>
        {item.refillReference}
      </GridCell>
    ),
    initialWidth: 200,
    managementGroup: "UI_DNM_Group_TankChannelRefill",
    filter: {
      name: "UI_Dnm_Column_RefillReference",
      property: "refillReference",
      component: StringFilter
    }
  },
  {
    columnTitle: "UI_Dnm_Column_SAPName",
    columnKey: ColumnSortProperty.SapName,
    render: (item, width, highlightText) => (
      <GridCell width={width} highlightText={highlightText}>
        {item.sapName}
      </GridCell>
    ),
    initialWidth: 200,
    managementGroup: "UI_DNM_Group_TankChannelRefill",
    filter: {
      name: "UI_Dnm_Column_SAPName",
      property: "sapName",
      component: StringFilter
    }
  },
  {
    columnTitle: "UI_Dnm_Column_attachmentUrl",
    columnKey: ColumnSortProperty.FileName,
    render: (item, width) => (
      <GridCell className="link pointer" width={width}>
        <LastDeliveryTicket
          title="filename"
          lastDelivery={{
            refillStart: item.refillDate,
            amountFilled: item.amountFilled,
            attachments: [
              {
                attachmentName: item.fileName || "",
                attachmentUrl: item.attachmentUrl || ""
              }
            ]
          }}
        />
      </GridCell>
    ),
    initialWidth: 200,
    hasOwnClickHandler: true,
    managementGroup: "UI_DNM_Group_TankChannelRefill",
    filter: {
      name: "UI_Dnm_Column_attachmentUrl",
      property: "attachment",
      component: StringFilter
    }
  },
  {
    columnTitle: "UI_Dnm_Column_Haulier",
    columnKey: ColumnSortProperty.HaulierTag,
    render: (item, width, highlightText) => (
      <GridCell width={width} highlightText={highlightText}>
        {item.haulierTag}
      </GridCell>
    ),
    initialWidth: 200,
    managementGroup: "UI_DNM_Group_TankChannelRefill",
    filter: {
      name: "UI_Dnm_Column_Haulier",
      property: "haulierTag",
      component: StringFilter
    }
  },
  {
    columnTitle: "UI_Dnm_Column_Confirmed",
    columnKey: ColumnSortProperty.Confirmed,
    render: (item, width, highlightText) => (
      <GridCell width={width} highlightText={highlightText}>
        <input type="checkbox" style={{accentColor: "green"}} checked={item.confirmed == true} readOnly />
      </GridCell>
    ),
    initialWidth: 200,
    managementGroup: "UI_DNM_Group_TankChannelRefill",
    filter: {
      name: "UI_Dnm_Column_Confirmed",
      property: "confirmed",
      component: BooleanFilter,
      componentProps: {
        values: [
          {
            value: true,
            header: 'Confirmed',
            sub: 'Show only confirmed deliveries'
          },
          {
            value: false,
            header: 'Unconfirmed',
            sub: 'Show only unconfirmed deliveries'
          },
          {
            value: undefined,
            default: true,
            header: 'Both',
            sub: 'Do not filter by this property'
          }
        ]
      }
    }
  },
];
