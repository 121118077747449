import { ReactWrapper } from "$pages/common/react-wrapper";
import DeliveryNoteManagerReact, { IDeliveryNoteManagerParams, IDeliveryNoteManagerProps } from "./delivery-note-manager.react";

export class DeliveryNoteManager extends ReactWrapper<IDeliveryNoteManagerProps, IDeliveryNoteManagerParams> {
  constructor() {
    super(DeliveryNoteManagerReact, params => ({
      params
    }));
  }
}
