import { IFilterGroup } from "$interfaces/iFilter";
import { isSomething } from "$lib/helpers";
import { revalidateAllActiveQueries } from "$pages/common/GraphQLFetcher";
import { SortDirection } from "$typings/graphql-codegen";
import { ColumnSortProperty } from "../../../src/config";

export enum MonthCountEnum {
  OneMonth = "OneMonth",
  TwoMonths = "TwoMonths",
  ThreeMonths = "ThreeMonths",
  FourMonths = "FourMonths",
  FiveMonths = "FiveMonths",
  SixMonths = "SixMonths",
  TwelveMonths = "TwelveMonths",
  AllTime = "AllTime"
}

export const defaultSelectedColumns = [
  ColumnSortProperty.SiteName,
  ColumnSortProperty.BUID,
  ColumnSortProperty.CustomerName,
  ColumnSortProperty.ProductName,
  ColumnSortProperty.RefillDate,
  ColumnSortProperty.AmountFilled,
  ColumnSortProperty.Source,
  ColumnSortProperty.ShipTo,
  ColumnSortProperty.Reference,
  ColumnSortProperty.SapAddress,
  ColumnSortProperty.SapCity,
  ColumnSortProperty.SapPostCode,
  ColumnSortProperty.SapName,
  ColumnSortProperty.FileName
];

export const defaultFilters: IFilterGroup[] = [
  {
    field: "unmatchedTickets",
    exclude: true,
    type: "boolean",
    filters: [{ value: true }]
  },
  {
    field: "timewindow",
    exclude: false,
    type: "string",
    filters: [{ value: MonthCountEnum.OneMonth }]
  }
];

export const defaultVariables = {
  sortDirection: SortDirection.Desc,
  sortProperty: ColumnSortProperty.RefillDate,
  filters: JSON.stringify(defaultFilters),
  freeTextQuery: [""],
  offset: 0,
  first: 100
};

export const sliderValues: Record<MonthCountEnum, number> = {
  OneMonth: 0,
  TwoMonths: 1,
  ThreeMonths: 2,
  FourMonths: 3,
  FiveMonths: 4,
  SixMonths: 5,
  TwelveMonths: 6,
  AllTime: 7
};

export function mapToRangeValue(value?: MonthCountEnum) {
  return isSomething(value) ? sliderValues[value] : 0;
}

export function loadNewData() {
  revalidateAllActiveQueries();
}
