import { useGridData } from "$components/grid/react-grid/gridhelper";
import {
  ConditionalOperator,
  IBooleanFilter,
  IDateFilter,
  IFilterGroup,
  ITextFilter,
  hashFilterGroup
} from "$interfaces/iFilter";
import { usePersistedParsedState } from "$lib/hooks/usePersistedState";
import {
  DeliveryNotesAttachmentsDocument,
  DeliveryNotesAttachmentsQuery,
  DeliveryNotesAttachmentsQueryVariables,
  DeliveryNotesListDocument,
  DeliveryNotesListQueryVariables,
  ElasticSearchPage
} from "$typings/graphql-codegen";
import React, {CSSProperties, FC, useEffect, useMemo, useState } from "react";
import { ColumnSortProperty, features } from "../../../src/config";
import { Button, ButtonGroup, Card, CardContent } from "@mui/material";
import Grid from "$components/grid/react-grid/grid.react";
import { getColumns } from "./delivery-note-manager-columns";
import { useCaseInsensitiveTranslation } from "$lib/hooks/case-insensitive-translation";
import { userHasFeature } from "$lib/authorizationHelper";
import { getSession } from "../../../src/config/sessionService";
import { AccessLevel } from "$interfaces/enums";
import DataFreshness from "$pages/common/data-freshness/data-freshness.react";
import { runSingleQuery } from "$pages/common/GraphQLFetcher";
import TextboxMultiline from "$components/textbox-multiline/textbox-multiline.react";
import { useDebounce } from "$lib/hooks/debounce";
import Icon from "$components/icons/icon/icon.react";
import { isEmpty, isSomething } from "$lib/helpers";
import Slider from "$components/slider/slider.react";
import { useIsTouchDevice } from "$lib/hooks/isTouchDevice";
import { saveBlob } from "$lib/fileHelpers";
import {
  MonthCountEnum,
  defaultFilters,
  defaultSelectedColumns,
  defaultVariables,
  loadNewData,
  mapToRangeValue,
  sliderValues
} from "./delivery-note-manager-utills";
import MuiLabeledCheckbox from "$components/checkbox/mui-checkbox.react";

export interface IDeliveryNoteManagerParams {
  freeText: string | undefined;
  date: string | undefined;
  showUnmatchedTickets: boolean | undefined;
}
export interface IDeliveryNoteManagerProps {
  params: IDeliveryNoteManagerParams;
}

const DeliveryNoteManager: FC<IDeliveryNoteManagerProps> = ({ params }) => {
  const [t, i18n] = useCaseInsensitiveTranslation();
  const [columnEditMode, setColumnEditMode] = useState(false);
  const [hasActiveFilters, setHasActiveFilters] = useState(false);
  const [showUnmatchedTickets, setShowUnmatchedTickets] = useState(false);
  const [loadingAllAttachmentNames, setLoadingAllAttachmentNames] = useState(
    false
  );
  const isTouchDevice = useIsTouchDevice();
  const [variables, setVariables] = usePersistedParsedState<
    DeliveryNotesListQueryVariables
  >(`DNM-variables`, defaultVariables);
  const [filters, setFilters] = useState<IFilterGroup[] | undefined>(
    variables.filters ? JSON.parse(variables.filters) : defaultFilters
  );
  const [selectedColumns, setSelectedColumns] = usePersistedParsedState<
    ColumnSortProperty[]
  >("DNMSelectedColumns", defaultSelectedColumns);

  const [freeTextQueryAsArray, setFreeTextQueryAsArray] = useState<
    string[] | undefined
  >([]);

  const [freeTextQueryValue, setFreeTextQueryValue] = useState<
    string | undefined
  >(
    typeof variables.freeTextQuery === "string"
      ? variables.freeTextQuery
      : variables.freeTextQuery?.join("\n")
  );
  const freeTextQueryValueDebounced = useDebounce(
    freeTextQueryValue?.trim(),
    400
  );
  const hasUnmatchedTicketAccess = userHasFeature(
    getSession(),
    features.unassignedDeliveryNotes,
    AccessLevel.Read
  );

  useEffect(() => {
    if (params && Object.keys(params).length) {
      checkForQueryParameters(params);
    }
  }, [params]);

  useEffect(() => {
    const asArray = freeTextQueryValueDebounced?.split("\n").map(q => q.trim());
    setVariables(variables => ({ ...variables, freeTextQuery: asArray }));
    setFreeTextQueryAsArray(asArray);
  }, [freeTextQueryValueDebounced]);

  useEffect(() => {
    setVariables(variables => ({
      ...variables,
      filters: JSON.stringify(filters)
    }));
  }, [filters]);

  useEffect(() => {
    if (variables === defaultVariables) return;

    const variablesFilterGroups: IFilterGroup[] = JSON.parse(
      isSomething(variables.filters) ? variables.filters : "[]"
    );
    const hashedVariablesFGs: string[] = variablesFilterGroups
      .map(hashFilterGroup)
      .sort();

    const _variables = {
      ...variables,
      filters: hashedVariablesFGs
    };

    const defaultFilterGroups: IFilterGroup[] = JSON.parse(
      isSomething(defaultVariables.filters) ? defaultVariables.filters : "[]"
    );
    const hashedDefaultFGs: string[] = defaultFilterGroups
      .map(hashFilterGroup)
      .sort();

    const _defaultVars = {
      ...defaultVariables,
      filters: hashedDefaultFGs
    };

    setHasActiveFilters(
      JSON.stringify(_variables) !== JSON.stringify(_defaultVars)
    );
  }, [variables]);

  const gridData = useGridData(
    DeliveryNotesListDocument,
    variables,
    data => data.deliveryNotes.totalCount,
    data => data.deliveryNotes.edges
  );

  useEffect(() => {
    if (filters) {
      const filter = filters?.find(a => a.field === "unmatchedTickets");
      setShowUnmatchedTickets(!filter?.exclude);
    }
  }, [filters]);

  const selectedMonthFilter = useMemo(() => {
    return filters?.find(a => a.field === "timewindow");
  }, [filters]);

  const selectedMonthValue = useMemo(() => {
    const timeWindowFilter = selectedMonthFilter;
    if (!timeWindowFilter) return MonthCountEnum.OneMonth;

    const [filterValue] = (timeWindowFilter.filters as ITextFilter[]).map(
      f => f.value
    );

    return filterValue as MonthCountEnum;
  }, [selectedMonthFilter]);

  const monthText = useMemo(() => {
    const monthFilter = selectedMonthValue;
    switch (monthFilter) {
      case MonthCountEnum.OneMonth:
        return "Show delivery tickets from the past month";
      case MonthCountEnum.TwoMonths:
        return "Show delivery tickets from the past 2 months";
      case MonthCountEnum.ThreeMonths:
        return "Show delivery tickets from the past 3 months";
      case MonthCountEnum.FourMonths:
        return "Show delivery tickets from the past 4 months";
      case MonthCountEnum.FiveMonths:
        return "Show delivery tickets from the past  5 months";
      case MonthCountEnum.SixMonths:
        return "Show delivery tickets from the past 6 months";
      case MonthCountEnum.TwelveMonths:
        return "Show delivery tickets from the past year";
      default:
        return "Show delivery tickets from all time";
    }
  }, [selectedMonthValue]);

  function toggleShowUnmatchedTickets() {
    const shouldShowUnmatchedTickets = !showUnmatchedTickets;

    const newFilter: IFilterGroup = {
      exclude: !shouldShowUnmatchedTickets,
      field: "unmatchedTickets",
      filters: [{ value: true }],
      type: "boolean"
    };
    filterChanged(newFilter);
  }

  function filterChanged(newfilter: IFilterGroup) {
    const newFilters = filters?.filter(f => f.field !== newfilter.field);

    if (newfilter.filters?.length) newFilters?.push(newfilter);

    setFilters(newFilters);
  }

  function getRowsInTextArea() {
    return typeof variables.freeTextQuery === "string"
      ? 1
      : Math.min(4, variables.freeTextQuery?.length || 1);
  }

  function checkForQueryParameters(
    params: IDeliveryNoteManagerParams | undefined
  ) {
    if (!isSomething(params)) return;
    const { freeText } = params;

    const filters: IFilterGroup[] = [];
    const freeTextQuery = freeText
      ? freeText.split("\n").filter(e => !isEmpty(e))
      : undefined;
    const dateQuery = params.date;
    const showUnmatchedTickets = params.showUnmatchedTickets;

    if (dateQuery) {
      const dateFilter: IDateFilter = {
        operator: ConditionalOperator.Equals,
        value: dateQuery,
        symbol: "="
      };
      filters.push({
        field: "refillDate",
        type: "date",
        exclude: false,
        filters: [dateFilter]
      });
    }

    if (showUnmatchedTickets) {
      const unmatched: IBooleanFilter = { value: true };
      filters.push({
        field: "unmatchedTickets",
        type: "boolean",
        exclude: false,
        filters: [unmatched]
      });
    }

    if (freeText || filters.length) {
      setVariables({ ...defaultVariables, freeTextQuery });
      setFreeTextQueryValue(freeText);
      filters.forEach(filterChanged);
    } else {
      setVariables(defaultVariables);
    }
  }

  function monthSliderChanged(value: number) {
    const recordEntry = Object.entries(sliderValues).find(
      ([_, recordValue]) => value === recordValue
    );
    if (!recordEntry) return;
    const newFilter: IFilterGroup = {
      exclude: false,
      field: "timewindow",
      type: "string",
      filters: [{ value: recordEntry[0] }]
    };
    filterChanged(newFilter);
  }

  async function downloadAttachmentNames() {
    try {
      setLoadingAllAttachmentNames(true);
      const { promise } = runSingleQuery<
        DeliveryNotesAttachmentsQuery,
        DeliveryNotesAttachmentsQueryVariables
      >(DeliveryNotesAttachmentsDocument, {});
      const { deliveryNotesAttachments } = await promise;
      promise.then(() => setLoadingAllAttachmentNames(false));
      const document = deliveryNotesAttachments;
      document
        ? saveBlob(new Blob([document]), "DeliveryNoteAttachmentList.txt")
        : null;
    } catch (error) {
      setLoadingAllAttachmentNames(false);
      throw new Error("Could not download");
    }
  }
  
  const FilterToolbarStyle: CSSProperties = {
    display: 'flex',
    gap: '16px',
    flexWrap: 'wrap',
    alignItems: 'center',
  }
  
  const ActionButtonGroupStyle: CSSProperties = {
    display: 'flex',
    gap: '16px',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'end',
    flexGrow: 1,
  }

  return (
    <div
      className="flex"
      style={{
        flex: "1 0 0px",
        display: "flex",
        flexDirection: "row",
        padding: "1rem"
      }}
    >
      <Card sx={{ display: "flex", flex: "1 0 0px", flexDirection: "column" }}>
        <CardContent sx={{
          padding: "14px",
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          gap: '16px'
        }}>
          <div style={FilterToolbarStyle}>
            <div style={{width: 200}}>
              <TextboxMultiline
                className="textbox-multiline"
                restrictResizeTo="horizontal"
                rows={getRowsInTextArea()}
                value={freeTextQueryValue}
                placeholder={t("UI_Filter_FilterTickets")}
                onChange={value => setFreeTextQueryValue(value)}
                shiftEnterAsNewline
                autofocus={!isTouchDevice}
              />
            </div>
            <div className="flex column">
              <span style={{ width: 300 }}>{monthText}</span>
              <Slider
                max={7}
                min={0}
                style={{ width: 250 }}
                value={mapToRangeValue(selectedMonthValue)}
                onChanged={value => monthSliderChanged(value.value as number)}
              />
            </div>
            {hasUnmatchedTicketAccess && (
              <MuiLabeledCheckbox
                label="Show unmatched tickets"
                checked={showUnmatchedTickets}
                onChange={() => toggleShowUnmatchedTickets()}
              />
            )}

            <div className="flex column jccenter">
              <span className="flex nowrap">
                <DataFreshness
                  prefixedText={`${gridData.data?.length || 0} tickets `}
                  pageToCheck={ElasticSearchPage.DeliveryNotes}
                  freshnessChanged={loadNewData}
                />
              </span>
            </div>
            {hasActiveFilters && (
              <ButtonGroup className="flex">
                <Button
                  variant="outlined"
                  style={{whiteSpace: "nowrap"}}
                  onClick={() => {
                    setFreeTextQueryValue("");
                    setVariables(defaultVariables);
                    setFilters(defaultFilters);
                  }}
                >
                  Clear filters
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => {
                    setFreeTextQueryValue("");
                    setVariables(defaultVariables);
                    setFilters(defaultFilters);
                  }}
                >
                  <Icon name="fa-times" />
                </Button>
              </ButtonGroup>
            )}
          </div>

          <div style={ActionButtonGroupStyle}>
            <ButtonGroup className="flex ai-start" sx={{alignItems: 'center'}}>
              <Button
                sx={{display: 'flex', whiteSpace: "nowrap", columnGap: '8px'}}
                className="flex"
                variant="outlined"
                onClick={() => setColumnEditMode(e => !e)}
              >
                <Icon name="columns"/>
                {columnEditMode
                  ? t("ui_sitelist_actions_closecolumneditor")
                  : t("ui_sitelist_actions_editcolumns")}
              </Button>
              <Button
                variant="outlined"
                onClick={downloadAttachmentNames}
                sx={{display: 'flex', whiteSpace: "nowrap", columnGap: '8px'}}
                disabled={loadingAllAttachmentNames}
              >
                <Icon
                  name={
                    loadingAllAttachmentNames ? "fa-spinner fa-pulse" : "download"
                  }
                />
                {t("UI_SiteList_Actions_DownloadAttachmentNames")}
              </Button>
            </ButtonGroup>
          </div>
        </CardContent>

        <Grid
          name="deliverynotemanager"
          items={gridData}
          columnEditMode={columnEditMode}
          loading={gridData.isRevalidating && !gridData.data}
          columns={getColumns(i18n.language)}
          freeTextQuery={freeTextQueryAsArray}
          highlightText={variables.freeTextQuery || undefined}
          selectedColumns={selectedColumns}
          selectedColumnsChanged={setSelectedColumns}
          filtersChanged={setFilters}
          activeFilters={filters}
          sortedDirection={variables.sortDirection || undefined}
          sortedColumnKey={variables.sortProperty || undefined}
          sortChanged={(sortProperty, sortDirection) =>
            setVariables(v => ({ ...v, sortProperty, sortDirection }))
          }
          showGroupedHeader
          rememberScroll
        />
      </Card>
    </div>
  );
};

export default DeliveryNoteManager;
